<template>
    <!-- BEGIN: CREATE ITEM DIALOG -->
    <Dialog v-model:visible="itemDialog" :style="{ width: '600px' }" header="Ajouter un service" :modal="true" class="p-fluid">
        <div>{{item.amountPerDay}}</div>
        <form name="projectItem" id="projectItem-form" @submit.prevent="saveItem(!v$.$invalid)" novalidate>
            <div class="p-formgrid p-grid " style="margin-bottom: 20px;">
                <div class="p-field p-col">
                    <label for="type">Type</label>
                    <Dropdown v-model="item.type" :options="types" placeholder="Sélectionne un poste" optionLabel="name" optionValue="type" :class="{ 'p-invalid': v$.item.type.$invalid && isSubmitted }" />
                    <div v-if="(v$.item.type.$invalid && isSubmitted) || v$.item.type.$pending.$response" class="p-error">
                        <small v-if="v$.item.type.required.$invalid">Veuillez renseigner ce champ.</small>
                    </div>
                </div>
                <div class="p-field p-col" v-if="getUser.isAdmin">
                    <label for="amountPerDay">Prix / jour</label>
                    <InputNumber v-model="item.amountPerDay" mode="currency" currency="EUR" :maxFractionDigits="0" :class="{ 'p-invalid': v$.item.amountPerDay.$invalid && isSubmitted }"/>
                    <div v-if="(v$.item.amountPerDay.$invalid && isSubmitted) || v$.item.amountPerDay.$pending.$response" class="p-error">
                        <small v-if="v$.item.amountPerDay.required.$invalid">Veuillez renseigner ce champ.</small>
                    </div>
                </div>
            </div>
            <div class="p-formgrid p-grid " style="margin-bottom: 20px;">
                <div class="p-field p-col">
                    <label for="nom">Quantitée</label>
                    <InputText class="mr-3" name="quantity" id="quantity" v-model="item.qty" required="true" :class="{ 'p-invalid': v$.item.qty.$invalid && isSubmitted }" />
                    <div v-if="(v$.item.qty.$invalid && isSubmitted) || v$.item.qty.$pending.$response" class="p-error">
                        <small v-if="v$.item.qty.required.$invalid">Veuillez renseigner ce champ.</small>
                        <small v-if="v$.item.qty.integer.$invalid">Vous devez renseigner un chiffre.</small>
                    </div>
                </div>
                <div class="p-field p-col">
                    <label for="period">Période</label>
                    <Dropdown v-model="item.period" :options="periode" placeholder="Sélectionne une fréquence" optionLabel="name" optionValue="value" :class="{ 'p-invalid': v$.item.period.$invalid && isSubmitted }" />
                    <div v-if="(v$.item.period.$invalid && isSubmitted) || v$.item.period.$pending.$response" class="p-error">
                        <small v-if="!v$.item.period.integer.$invalid">Veuillez renseigner ce champ.</small>
                    </div>
                </div>
            </div>
        </form>
        <template #footer>
            <Button label="Annuler" icon="pi pi-times" class="p-button-danger p-button-text" @click="hideDialog()" />
            <Button label="Enregistrer" icon="pi pi-check" class="p-button-success" type="submit" form="projectItem-form" />
        </template>
    </Dialog>
    <!-- END: CREATE ITEM DIALOG -->
</template>

<script>
import { mapGetters } from 'vuex'
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import ProjectService from '../../service/ProjectService'

export default {
    name: 'project-item-dialog',
    data() {
        return {
            item: null,
            isSubmitted: false,
            itemDialog: false,
            types: [],
            periode: [
                { name: 'Toutes les semaines', value: '1WP' },
                { name: 'Tous les mois', value: '1MP' },
            ]
        };
    },
    emits: ['onUpdate', 'onCreate'],
    props: {
        projectId: Number,
    },
    computed: {
        ...mapGetters(['getUser'])
    },
    validations() {
        return {
            item: {
                type: { required },
                period: { required },
                qty: { required },
                amountPerDay: { required }
            },
        };
    },
    setup: () => ({ v$: useVuelidate() }),
    mounted () {
        this.types = ProjectService.getTypes();
    },
    methods: {
        openNew() {
            this.item = {};
            this.isSubmitted = false;
            this.itemDialog = true;
        },
        hideDialog() {
            this.item = {};
            this.v$.$reset();
            this.itemDialog = false;
            this.isSubmitted = false;
        },
        edit(item) {
            this.item = { 
                amountPerDay: item.amountPerDay,
                id: item.id,
                period: item.period,
                qty: item.qty,
                type: item.type
            };
            this.itemDialog = true;
        },
        saveItem(isValid) {
            this.isSubmitted = true;
            if (!isValid) return;

            this.item.qty=String(this.item.qty);

            if (this.item.id) {
                this.$resources.projectItems.update(this.item.id, this.item).then(response => {
                    this.hideDialog();
                    this.$emit('onUpdate', response);
                });
            } else {
                this.item.project = '/projects/' + this.projectId;
                this.$resources.projectItems.create(this.item).then(response => {
                    this.hideDialog();
                    this.$emit('onCreate', response);
                });
            }
        },
    },
};
</script>
<style lang="scss"></style>