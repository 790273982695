<template>
    <!-- BEGIN: CREATE ITEM DIALOG -->
    <Dialog v-model:visible="timeDialog" :style="{ width: '600px' }" header="Ajouter du temps" :modal="true" class="p-fluid">
       <form name="projectTime" id="projectTime-form" @submit.prevent="saveTime(!v$.$invalid)" novalidate>
            <div class="p-field">
                <label for="type">Type</label>
                <Dropdown v-model="item.projectItem" :options="items" placeholder="Sélectionne un poste" optionLabel="name" optionValue="@id" :class="{ 'p-invalid': v$.item.projectItem.$invalid && isSubmitted }" />
                <div v-if="(v$.item.projectItem.$invalid && isSubmitted) || v$.item.projectItem.$pending.$response" class="p-error">
                    <small v-if="v$.item.projectItem.required.$invalid">Veuillez renseigner ce champ.</small>
                </div>
            </div>
            <div class="p-formgrid p-grid" style="margin-bottom: 20px">
                <div class="p-field p-col">
                    <label for="date">Date</label>
                    <Calendar v-model="item.date" selectionMode="single" dateFormat="dd/mm/yy" :maxDate="maxDate" :class="{ 'p-invalid': v$.item.date.$invalid && isSubmitted }"/>
                    <div v-if="(v$.item.date.$invalid && isSubmitted) || v$.item.date.$pending.$response" class="p-error">
                        <small v-if="v$.item.date.required.$invalid">Veuillez choisir une date.</small>
                    </div>
                </div>
                <div class="p-field p-col">
                    <label for="count">Durée</label>
                    <InputNumber v-model="item.count" mode="decimal" :minFractionDigits="2" placeholder="0,00 jour" suffix=" jour" :class="{ 'p-invalid': v$.item.count.$invalid && isSubmitted }" />
                    <div v-if="(v$.item.count.$invalid && isSubmitted) || v$.item.count.$pending.$response" class="p-error">
                        <small v-if="v$.item.count.required.$invalid">Veuillez renseigner ce champ.</small>
                        <small v-else-if="!v$.item.count.between">La durée doit être comprise entre 0,1 et 1 jour.</small>
                    </div>
                </div>
            </div>
        </form>
        <template #footer>
            <Button label="Annuler" icon="pi pi-times" class="p-button-danger p-button-text" @click="hideDialog()" />
            <Button label="Enregistrer" icon="pi pi-check" class="p-button-success" type="submit" form="projectTime-form" />
        </template>
    </Dialog>
    <!-- END: CREATE ITEM DIALOG -->
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required, between } from '@vuelidate/validators';
import ProjectService from '../../service/ProjectService'
import moment from 'moment';

export default {
    name: 'project-time-dialog',
    data() {
        return {
            users: [],
            items: [],
            item: {},
            isSubmitted: false,
            timeDialog: false,
            maxDate: null,
            types : {}
        };
    },
    props: {
        projectId: Number,
    },
    emits: ['onCreate'],
    validations() {
        return {
            item: {
                projectItem: { required },
                count: {required, betweenValue: between(0.1, 1) },
                date: { required}
            }
        };
    },
    setup: () => ({ v$: useVuelidate() }),
    mounted(){
        this.reloadData();
        this.types = ProjectService.getTypeMapping();
        this.maxDate = new Date();
    },
    methods: {
        openNew() {
            // Reload data
            this.reloadData();
            this.item = {};
            this.isSubmitted = false;
            this.timeDialog = true;
        },
        reloadData() {
            this.$resources.users.list().then(response => (this.users = response.data));
            this.$resources.projectItems.list(0,{project: this.projectId}).then(response => {
                this.items = response.data.map((item) => {
                    item.name = this.types[item.type];
                    return item;
                });
            });
        },
        hideDialog() {
            this.item = {};
            this.v$.$reset();
            this.timeDialog = false;
            this.isSubmitted = false;
        },
        saveTime(isValid) {
            this.isSubmitted = true;
            if (!isValid) return;

            let data = {...this.item};
            data.count = String(data.count);
            data.date = moment(data.date, "DD-MM-YYYY").format('YYYY-MM-DD')

            this.$resources.projectUserEntries.create(data).then( (response) => {
                this.hideDialog();
                this.$emit('onCreate', response);
            });
        }
    }
    
};
</script>
<style lang="scss">
</style>