<template>
    <!--BEGIN: CREATE VIEW DIALOG -->
    <Dialog v-model:visible="projectItemEntriesDialog" :style="{ width: '800px' }" header="Suivi du service" :modal="true" class="p-fluid">
        <div class="card">
            <FullCalendar :events="events" :options="options" />

            <!--DataTable :value="entries" dataKey="id" stripedRows responsiveLayout="scroll" class="p-datatable-sm">
                <Column field="createdAt" header="Date de démarrage">
                    <template #body="slotProps">
                        {{ formatDate(slotProps.data.date) }}
                    </template>
                </Column>
                <Column field="count" header="Quantité">
                    <template #body="slotProps">
                        {{ slotProps.data.count }}
                    </template>
                </Column>
                <Column field="user" header="Rapporteur">
                    <template #body="slotProps">
                        {{ slotProps.data.owner.fullName }}
                    </template>
                </Column>
            </DataTable-->
        </div>
    </Dialog>
    <!-- END: CREATE VIEW DIALOG -->
</template>

<script>
import moment from 'moment';
import dayGridPlugin from '@fullcalendar/daygrid';
import frLocale from '@fullcalendar/core/locales/fr';

export default {
    name: 'project-list-item-entry-dialog',
    data() {
        return {
            projectItemEntriesDialog: false,
            events: [],
            options: {
                initialView: 'dayGridMonth',
                locale: frLocale,
                timeZone: 'UTC',
				plugins:[dayGridPlugin],
				headerToolbar: {
					left: 'prev,next',
					center: 'title',
					right: ''
				},
				editable: false,
                weekends: false
            },
        };
    },
    methods: {
        formatDate(value) {
            if (value) {
                return moment(String(value)).format('DD/MM/YYYY');
            }
            return '-';
        },
        open(projectItem) {
            this.$resources.projectItems.child(projectItem.id, 'entries').then(response => {
                this.events = response.data.map((entry) => {
                    return {
                        title: entry.owner.fullName + ' (' + entry.count + 'j)',
                        date: moment(entry.date).format('YYYY-MM-DD')
                    }
                });
            });
            this.projectItemEntriesDialog = true;
        }
    },
};
</script>

<style lang="scss">
@media screen and (max-width: 960px) {
    ::v-deep(.fc-header-toolbar) {
        display: flex;
        flex-wrap: wrap;
    }
}
</style>
