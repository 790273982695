<template>
    <Toast />
    <div class="p-grid dashboard">
        <div class="p-col-12 p-md-6">
            <div class="card donut">
                <h5>Répartition des services</h5>

                <div class="container">
                    <Chart type="doughnut" :data="getChartData()" :options="lightOptions" />
                </div>
            </div>
        </div>
        <div class="p-col-12 p-md-6">
            <div class="card">
                <h5>
                    Projet: <small>{{ project.name }}</small>
                </h5>
                <p>{{ project.description }}</p>
                <div class="project-detail">
                    <ul>
                        <li>
                            Date de début
                            <div class="value">{{ formatDate(project.startAt) }}</div>
                        </li>
                        <li>
                            Date de fin
                            <div class="value">{{ formatDate(project.endAt) }}</div>
                        </li>
                        <li v-if="getUser.isAdmin">
                            Budget <div class="value">{{project.total_amount}} &euro;</div>
                        </li>
                    </ul>
                </div>
                <Button label="Modifier" icon="pi pi-pencil" class="p-button-info p-mr-2" @click="$refs.projectDialog.editProject(project)" />
                <Button label="Ajouter un service" icon="pi pi-plus" class="p-button-success p-mr-2" @click="$refs.projectItemDialog.openNew()" v-if="project.status == 'progress'"/>
                <Button label="Ajouter du temps " icon="pi pi-plus" class="p-button-time" @click="$refs.projectTimeDialog.openNew(project.id)"  v-if="project.status == 'progress'"/>
            </div>
        </div>
        <div class="p-col-12">
            <div class="card">
                <DataTable :value="items" dataKey="id" stripedRows responsiveLayout="scroll" class="p-datatable-sm">
                    <Column field="type" header="Type">
                        <template #body="slotProps">
                            {{ this.types[slotProps.data.type] }}
                        </template>
                    </Column>
                    <Column field="period" header="Période">
                        <template #body="slotProps">
                            {{ slotProps.data.period }}
                        </template>
                    </Column>
                    <Column field="qty" header="Qt.">
                        <template #body="slotProps">
                            {{ slotProps.data.qty }}
                        </template>
                    </Column>
                    <Column field="advanced" header="Avancement">
                        <template #body="slotProps">
                            <ProgressBar :value="progress(slotProps.data)" :class="progressColor(progress(slotProps.data))"> {{ progress(slotProps.data) }}% </ProgressBar>
                        </template>
                    </Column>
                    <Column field="amountPerDay" header="TJM" :style="getUser.isAdmin ? '' : 'display: none;'">
                        <template #body="slotProps">
                            {{ slotProps.data.amountPerDay }} &euro;
                        </template>
                    </Column>
                    <Column>
                        <template #body="slotProps">
                            <div class="p-text-right">
                                <Button icon="pi pi-eye" class="p-button-rounded p-button-info p-mr-2" @click="$refs.projectListItemEntryDialog.open(slotProps.data)" />
                                <Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" @click="$refs.projectItemDialog.edit(slotProps.data)" />
                                <Button icon="pi pi-trash" v-if="slotProps.data.total_entry == 0" class="p-button-rounded p-button-danger" @click="confirmDeleteItem(slotProps.data)" />
                            </div>
                        </template>
                    </Column>
                </DataTable>
            </div>

            <!-- BEGIN: DELETE ITEM DIALOG -->
            <Dialog v-model:visible="deleteItemDialog" :style="{ width: '450px' }" header="Confirm" :modal="true">
                <div class="confirmation-content">
                    <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
                    <span v-if="item"
                        >Are you sure you want to delete <b>{{ item.type }}</b
                        >?</span
                    >
                </div>
                <template #footer>
                    <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteItemDialog = false" />
                    <Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteItem" />
                </template>
            </Dialog>
            <!-- END: DELETE ITEM DIALOG -->
        </div>

        <project-dialog ref="projectDialog" @onUpdate="onProjectUpdated($event)"></project-dialog>
        <project-item-dialog ref="projectItemDialog" :project-id="id" @onUpdate="onProjectItemUpdated($event)" @onCreate="onProjectItemCreated($event)"></project-item-dialog>
        <project-time-dialog ref="projectTimeDialog" :project-id="id" @onCreate="onProjectTimeCreated($event)"></project-time-dialog>
        <ProjectListItemEntryDialog ref="projectListItemEntryDialog"></ProjectListItemEntryDialog>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import moment from 'moment';
import ProjectDialog from './ProjectDialog.vue';
import ProjectItemDialog from './ProjectItemDialog.vue';
import ProjectTimeDialog from './ProjectTimeDialog.vue';
import ProjectListItemEntryDialog from './ProjectListItemEntryDialog.vue'
import ProjectService from '../../service/ProjectService'

export default {
    components: {
        ProjectDialog,
        ProjectItemDialog,
        ProjectTimeDialog,
        "ProjectListItemEntryDialog": ProjectListItemEntryDialog
    },
    data() {
        return {
            items: [], 
            deleteItemDialog: false,
            deleteItemsDialog: false,
            isSubmitted: false,
            project: {},
            types: [],
            periode: [
                { name: 'Toutes les semaines', value: '1WP' },
                { name: 'Tous les mois', value: '1MP' },
            ],
            lightOptions: {
                plugins: {
                    legend: {
                        labels: {
                            color: '#495057',
                        },
                    },
                },
            }
        };
    },
    props: {
        id: String,
    },
    validations() {
        return {
            item: {
                type: { required },
                period: { required },
                qty: { required },
                project: [],
                amountPerDay: { required }
            },
        };
    },
    setup: () => ({ v$: useVuelidate() }),
    mounted() {
        this.reloadData();
        this.types = ProjectService.getTypeMapping();
    },
    computed: {
        ...mapGetters(['getUser'])
    },
    methods: {
        reloadData() {
            this.$resources.projectItems.list(0, { project: this.id }).then(response => (this.items = response.data));
            this.$resources.projects.get(this.id).then(response => (this.project = response));
        },
        formatDate(value) {
            if (value) {
                return moment(String(value)).format('DD/MM/YYYY');
            }
            return '-';
        },
        progressColor(value) {
            if (value);
            return {
                'progress-green': value < 50,
                'progress-orange': value >= 50 && value < 90,
                'progress-red': value >= 90,
            };
        },
        progress(item) {
            return Math.floor((item.total_entry * 100) / item.qty);
        },
        getChartData() {
            let data = {
                labels: [],
                datasets: [
                    {
                        data: [],
                        backgroundColor: ['#003f5c', '#2f4b7c', '#665191', '#a05195', '#d45087', '#f95d6a', '#ff7c43', '#ffa600'],
                        hoverBackgroundColor: ['#006492', '#4a69ab', '#8768b6', '#c061af', '#ee5c96', '#ff6671', '#ff8146', '#ffa600'],
                    },
                ],
            };

            let i = 0;
            for (const item of this.items) {
                i++;
                if (i > 5) continue;
                data.labels.push(item.type);
                data.datasets[0].data.push(item.qty);
            }

            return data;
        },
        confirmDeleteItem(item) {
            this.item = item;
            this.deleteItemDialog = true;
        },
        deleteItem() {
            this.$resources.projectItems.delete(this.item.id).then(() => {
                this.items = this.items.filter(val => val.id !== this.item.id);
                this.deleteItemDialog = false;

                this.item = {};
                this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Element supprimé', life: 3000 });
            });
        },
        onProjectUpdated(data) {
            this.project = { ...data };
            this.$toast.add({ severity: 'success', summary: 'Success Message', detail: 'Le projet a bien été modifié !', life: 3000 });
        },
        onProjectItemUpdated() {
            this.reloadData();
            this.$toast.add({ severity: 'success', summary: 'Success Message', detail: 'Le service a bien été modifié !', life: 3000 });
        },
        onProjectItemCreated(data) {
            this.items.push(data);
            this.$toast.add({ severity: 'success', summary: 'Success Message', detail: 'Le service a bien été ajouté !', life: 3000 });
        },
        onProjectTimeCreated() {
            this.reloadData();
            this.$toast.add({severity:'success', summary: 'Success Message', detail:'Le temps a bien été ajouté !', life: 3000});
        }, 
    },
};
</script>

<style lang="scss">
.p-datatable .p-datatable-tbody .p-datatable-thead .p-column-title {
    display: block !important;
}
strong {
    color: red;
}
.table-header {
    display: flex;
    justify-content: space-between;
}

.product-image {
    width: 100px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    color: #006492;
}

.p-dialog .product-image {
    width: 150px;
    margin: 0 auto 2rem auto;
    display: block;
}
.formatDesc {
    white-space: pre;
}

.confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.product-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;

    &.status-instock {
        background: #c8e6c9;
        color: #256029;
    }

    &.status-outofstock {
        background: #ffcdd2;
        color: #c63737;
    }

    &.status-lowstock {
        background: #feedaf;
        color: #8a5340;
    }
}

.project-detail {
    color: #313842;

    ul {
        padding: 0;
        list-style: none;
        border: solid 1px #dfe6ee;

        li {
            padding: 10px;
            border-bottom: solid 1px #dfe6ee;

            &:last-of-type {
                border-bottom: none;
            }

            .value {
                font-weight: 700;
                float: right;
            }

            &:hover {
                background-color: #dfe6ee;
            }
        }
    }
}

.p-progressbar.progress-yellow .p-progressbar-value {
    background: #fbc02d !important;
}

.p-progressbar.progress-orange .p-progressbar-value {
    background: #f57c00 !important;
}

.p-progressbar.progress-red {
    .p-progressbar-value {
        background: #d62d20 !important;
    }
    .p-progressbar-label {
        color: #fff !important;
    }
}

.p-progressbar.progress-green .p-progressbar-value {
    background: #4caf50 !important;
}
@keyframes p-progress-spinner-color {
    100%,
    0% {
        stroke: #d62d20;
    }
    40% {
        stroke: #0057e7;
    }
    66% {
        stroke: #008744;
    }
    80%,
    90% {
        stroke: #ffa700;
    }
}

.p-button.p-button-text {
    background-color: transparent;
    color: #f8f9fa;
    border-color: transparent;
}

::v-deep(.p-toolbar) {
    flex-wrap: wrap;
    .p-button {
        margin-bottom: 0.25rem;
    }
}
.p-button-time {
    background: #f4a22e;
    border-color: #f4a22e;
}
</style>
